import { Injectable } from '@angular/core';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { Observable } from 'rxjs';
import { anonymousUser } from '../domain/anonymous-user.constant';
import { FeatureFlags, TempFeatureFlags } from '../domain/feature-flags.enum';
import { LaunchDarklyService } from '../feat-launch-darkly-service/launch-darkly.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureProviderService {
  private isIdentified = false;

  constructor(private readonly launchDarklyService: LaunchDarklyService) {}

  /**
   * @deprecated The method should not be used
   */
  public getFeature<T = boolean>(key: FeatureFlags | TempFeatureFlags, defaultValue: T): T {
    return this.launchDarklyService.getFeature(key, defaultValue);
  }

  public getFeature$<T = boolean>(key: FeatureFlags | TempFeatureFlags, defaultValue: T): Observable<T> {
    return this.launchDarklyService.getFeature$(key, defaultValue);
  }

  public async identify(user: LDUser, isIdentified = true): Promise<void> {
    await this.launchDarklyService.identify(user);
    this.isIdentified = isIdentified;
  }

  public async unidentify(): Promise<void> {
    if (this.isIdentified) {
      await this.identify(anonymousUser, false);
    }
  }
}
