import { inject, Injectable, makeStateKey, NgZone, TransferState } from '@angular/core';
import { Environment, IS_PRODUCTION, PlatformService } from '@rma/generic/util/environment';
import { basicLogger, type LDClient, LDOptions, LDUser } from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { fromEvent, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FeatureFlags, TempFeatureFlags } from '../domain/feature-flags.enum';
import { LAUNCH_DARKLY_BOOTSTRAP, LAUNCH_DARKLY_INITIALISE, LaunchDarklyInitialise } from '../domain/launch-darkly-initialise.const';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private readonly zone = inject(NgZone);
  private readonly featureSetKey = makeStateKey<LDFlagSet>('ld_flags');
  private readonly environment = inject(Environment);
  private readonly platformService = inject(PlatformService);
  private readonly initialise: LaunchDarklyInitialise = inject(LAUNCH_DARKLY_INITIALISE);
  private readonly state = inject(TransferState, { optional: true });
  private readonly flags = inject(LAUNCH_DARKLY_BOOTSTRAP, { optional: true });

  private ldClient?: LDClient;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private featureWatchers: { [key: string]: Observable<any> } = {};

  public load(): Promise<void> {
    const { launchDarklyClientId } = this.environment;
    return new Promise((resolve, reject) => {
      this.zone.runOutsideAngular(() => {
        const flags = this.flags ? this.flags : this.state?.get(this.featureSetKey, undefined);
        const bootstrapConfig = flags && Object.values(flags).length ? { bootstrap: flags } : {};

        const config: LDOptions = {
          ...bootstrapConfig,
          logger: basicLogger({ level: IS_PRODUCTION ? 'error' : 'warn' }),
          sendEvents: this.platformService.isPlatformBrowser,
        };

        this.ldClient = this.initialise(launchDarklyClientId, { key: 'anonymous', anonymous: true }, config);

        this.ldClient.on('ready', () => {
          if (this.ldClient && this.platformService.isPlatformServer && this.state) {
            this.state.set(this.featureSetKey, this.ldClient.allFlags());
          }
          resolve();
        });

        this.ldClient.on('error', (error) => {
          console.error('Error initializing LaunchDarkly', error);
          reject(error);
        });
      });
    });
  }

  public getFeature<T = boolean>(key: FeatureFlags | TempFeatureFlags, defaultValue: T): T {
    return this.ldClient?.variation(key, defaultValue) ?? defaultValue;
  }

  public getFeature$<T = boolean>(key: FeatureFlags | TempFeatureFlags, defaultValue: T): Observable<T> {
    if (this.featureWatchers[key]) {
      return this.featureWatchers[key];
    }

    const initialValue: T = this.ldClient?.variation(key, defaultValue) ?? defaultValue;

    const feature$ =
      this.ldClient && this.platformService.isPlatformBrowser
        ? fromEvent<T[]>(this.ldClient, `change:${key}`).pipe(
            map((x) => x[1]), // array of previous/current
            startWith(initialValue),
          )
        : of(initialValue);

    this.featureWatchers[key] = feature$;

    return feature$;
  }

  async identify(user: LDUser): Promise<void> {
    if (this.ldClient) {
      await this.ldClient.identify(user);
    }
  }
}
