// Only Perm Features (Should be marked as a permanent flag in LaunchDarkly)
export enum FeatureFlags {
  AboutPage = 'Web.AboutPage',
  AdditionalBlogUrls = 'Web.AdditionalBlogUrls',
  AgentLanderUrl = 'Web.AgentLanderUrl',
  AgentLandingPartnerships = 'Web.AgentLandingPartnerships',
  AircallPhoneNumbers = 'Web.AircallPhoneNumbers',
  AwardsConfig = 'Web.AwardsConfig',
  AwardsCurrentYear = 'Awards.CurrentYear',
  BrandFooterLink = 'Web.FooterBrandUrl',
  ContactSalesPhoneNumber = 'Web.ContactSalesPhoneNumber',
  ContactUsUrl = 'Web.ContactUsUrl',
  CorporateRatesRequestFormUrl = 'Web.CorporateRatesRequestFormUrl',
  DatasourceDiscalimer = 'Web.DatasourceDisclaimer',
  DisableCloudinary = 'Web.DisableCloudinary',
  EnableAwards = 'Core.EnableAwards',
  EnableBuyerAgents = 'Core.EnableBuyerAgents',
  EnableCustomAds = 'Web.EnableCustomAds',
  EnableFinance = 'Core.EnableFinance',
  EnableFreeTrialCta = 'Public.EnableFreeTrialCta',
  EnableHomepageAgentCta = 'EnableHomepageAgentCta',
  EnableHomepageCaseStudies = 'EnableHomepageCaseStudies',
  EnableImageCaching = 'Web.EnableImageCache',
  EnableIntercom = 'Web.EnableIntercomLiveChat',
  EnableLeadAgentDisclaimer = 'Web.EnableLeadAgentDisclaimer',
  EnableLeasing = 'Web.EnableLeasing',
  EnableListingAttribution = 'Web.EnableListingAttribution',
  EnableMlsFooterLink = 'Web.ShowMlsLicensingFooterLink',
  EnableMobileNumberForMarketReport = 'Core.EnableMobileNumberForMarketReport',
  EnableNewOfficeSubscriptions = 'Web.EnableNewOfficeSubscriptions',
  EnablePromoter = 'Web.EnablePromoter',
  EnableSettlementPeriod = 'Core.EnableSettlementPeriod',
  EnableTeams = 'Core.EnableTeams',
  EnableUnrequestedReviews = 'Core.EnableUnrequestedReviews',
  FaqFooterLink = 'Web.FooterFaqUrl',
  FindMyAgentPopupBanner = 'FindMyAgent.PopupBanner',
  FindMyAgentPropertyTypes = 'FindMyAgent.PropertyTypes',
  FindMyAgentSearchBanner = 'FindMyAgent.SearchBanner',
  FooterAwardsUrl = 'Web.FooterAwardsUrl',
  FooterShowSupportEmailToUnsubscribedUsers = 'Web.FooterShowSupportEmailToUnsubscribedUsers',
  FooterShowSupportNumberToUnsubscribedUsers = 'Web.FooterShowSupportNumberToUnsubscribedUsers',
  HasMandatoryLicenceField = 'HasMandatoryLicenceField',
  HomepageSearchImage = 'Web.HomepageSearchImage',
  PromoteUnverified = 'Promoter.PromoteUnverified',
  PropertyTypeGrouping = 'Web.PropertyTypeGrouping',
  ShowReviewsOnListingLandingPages = 'LandingPages.Listing.ShowReviews',
  SubscriptionTierChannels = 'Web.SubscriptionPlanIntegrations',
  SupportEmail = 'Web.SupportEmail',
  TeamsBillingGrouping = 'Web.TeamsBillingGrouping',
  TrainingWebinarUrl = 'TrainingWebinarUrl',
  TypeformSurvey2023Snippet = 'Web.TypeformSurvey2023Snippet',
  SupportCentreUrl = 'Core.SupportCentreUrl',
}

// Temporary Features
export enum TempFeatureFlags {
  EnableBrokerageProInterestCheck = 'Temp.EnableBrokerageProInterestCheck',
  HideDom = 'Temp.Public.HideDOM2023',
  OfficeSubscriptionContactRequest = 'Web.OfficeSubscriptionContactRequest',
  SpanishReviews = 'Temp.EnableSpanishReviewFlow',
  ProfileReviewAttributes = 'Temp.ProfileReviewAttributes',
}
